// Generated by Framer (58a021c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zSeKybpXA", "bQ5hBXfs4", "nfo1q3Okr", "Q0uEpI98Q", "mmSjr9Jh1", "FQqIKPl25", "ykUQkzarq", "F1EYtK9W6"];

const variantClassNames = {bQ5hBXfs4: "framer-v-o7ri2e", F1EYtK9W6: "framer-v-7pvr7z", FQqIKPl25: "framer-v-1ldhit1", mmSjr9Jh1: "framer-v-1lnkq10", nfo1q3Okr: "framer-v-vzwh3m", Q0uEpI98Q: "framer-v-kaziri", ykUQkzarq: "framer-v-1f8yh0w", zSeKybpXA: "framer-v-g6tncv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"120px": "F1EYtK9W6", "12PX": "bQ5hBXfs4", "16PX": "nfo1q3Okr", "24PX": "Q0uEpI98Q", "48PX": "mmSjr9Jh1", "64PX": "FQqIKPl25", "8PX": "zSeKybpXA", "96px": "ykUQkzarq"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "zSeKybpXA", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zSeKybpXA", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mNNKD", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-g6tncv", className)} data-framer-name={"8PX"} layoutDependency={layoutDependency} layoutId={"zSeKybpXA"} ref={ref} style={{...style}} {...addPropertyOverrides({bQ5hBXfs4: {"data-framer-name": "12PX"}, F1EYtK9W6: {"data-framer-name": "120px"}, FQqIKPl25: {"data-framer-name": "64PX"}, mmSjr9Jh1: {"data-framer-name": "48PX"}, nfo1q3Okr: {"data-framer-name": "16PX"}, Q0uEpI98Q: {"data-framer-name": "24PX"}, ykUQkzarq: {"data-framer-name": "96px"}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mNNKD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mNNKD .framer-14pizrk { display: block; }", ".framer-mNNKD .framer-g6tncv { height: 8px; overflow: hidden; position: relative; width: 61px; }", ".framer-mNNKD.framer-v-o7ri2e .framer-g6tncv { height: 12px; }", ".framer-mNNKD.framer-v-vzwh3m .framer-g6tncv { height: 16px; }", ".framer-mNNKD.framer-v-kaziri .framer-g6tncv { height: 24px; }", ".framer-mNNKD.framer-v-1lnkq10 .framer-g6tncv { height: 48px; }", ".framer-mNNKD.framer-v-1ldhit1 .framer-g6tncv { height: 64px; }", ".framer-mNNKD.framer-v-1f8yh0w .framer-g6tncv { height: 96px; }", ".framer-mNNKD.framer-v-7pvr7z .framer-g6tncv { height: 120px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 8
 * @framerIntrinsicWidth 61
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bQ5hBXfs4":{"layout":["fixed","fixed"]},"nfo1q3Okr":{"layout":["fixed","fixed"]},"Q0uEpI98Q":{"layout":["fixed","fixed"]},"mmSjr9Jh1":{"layout":["fixed","fixed"]},"FQqIKPl25":{"layout":["fixed","fixed"]},"ykUQkzarq":{"layout":["fixed","fixed"]},"F1EYtK9W6":{"layout":["fixed","fixed"]}}}
 */
const FramerUK30UbNGF: React.ComponentType<Props> = withCSS(Component, css, "framer-mNNKD") as typeof Component;
export default FramerUK30UbNGF;

FramerUK30UbNGF.displayName = "Gap";

FramerUK30UbNGF.defaultProps = {height: 8, width: 61};

addPropertyControls(FramerUK30UbNGF, {variant: {options: ["zSeKybpXA", "bQ5hBXfs4", "nfo1q3Okr", "Q0uEpI98Q", "mmSjr9Jh1", "FQqIKPl25", "ykUQkzarq", "F1EYtK9W6"], optionTitles: ["8PX", "12PX", "16PX", "24PX", "48PX", "64PX", "96px", "120px"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerUK30UbNGF, [])